<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    title="Manage Chat Participants"
    button2-text="Save"
    @button2="save"
    :max-width="800"
  >
    <template v-slot:content>
      <v-card flat min-height="400">
        <v-card-text>
          <v-row dense align="stretch" justify="center">
            <v-col :cols="mdAndUp ? 6 : 12">
              <v-text-field
                outlined
                hide-details="auto"
                dense
                filled
                label="Search for people to add"
                clearable
                prepend-inner-icon="search"
                v-model="keyword"
                @click:clear="keyword = null"
              />

              <v-list
                dense
                v-if="filtered.length > 0"
                id="filtered_by_search_wrapper"
              >
                <v-list-item
                  v-for="item in filtered"
                  :key="item.id"
                  @click="add(item)"
                >
                  <v-list-item-avatar>
                    <v-img
                      :lazy-src="settings.loader"
                      contain
                      :src="item.image_url"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="primary--text"
                      v-text="item.fullname"
                    />
                    <v-list-item-subtitle v-text="item.job_title" />
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon> mdi-plus </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <Empty v-else headline="No more available members" />
            </v-col>
            <v-col :cols="mdAndUp ? 6 : 12">
              <v-list dense id="all_selected_wrapper">
                <v-subheader class="primary--text fw-700 fs-16">
                  Selected ({{ selected.length }})
                </v-subheader>
                <template v-if="selected.length > 0">
                  <v-list-item v-for="item in selected" :key="item.id">
                    <v-list-item-avatar>
                      <v-img
                        :lazy-src="settings.loader"
                        contain
                        :src="item.image_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        class="primary--text"
                        v-text="item.fullname"
                      />
                      <v-list-item-subtitle v-text="item.job_title" />
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon
                        v-if="can_be_remove(item)"
                        @click="remove(item)"
                        color="error"
                      >
                        close
                      </v-icon>
                      <v-icon v-else> mdi-lock </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import _ from 'lodash'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
export default {
  name: 'ManageChatMembers',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    current: { type: Array, default: () => [] },
    list: { type: Array, default: () => [] },
    dontRemove: { type: Array, default: () => [] }
  },
  computed: {
    selected_ids() {
      return this.selected.length
        ? _.cloneDeep(this.selected.map((i) => i.id))
        : []
    },
    filtered() {
      if (this.keyword) {
        return this.list.length
          ? _.cloneDeep(this.list).filter((i) => {
              return !this.selected_ids.includes(i.id) &&
                this.keyword &&
                (i.first_name
                  .toLowerCase()
                  .includes(this.keyword.toLowerCase()) ||
                  i.last_name
                    .toLowerCase()
                    .includes(this.keyword.toLowerCase()))
                ? true
                : false
            })
          : []
      } else {
        return this.list.length
          ? _.cloneDeep(this.list).filter((item) => {
              return item && !this.selected_ids.includes(item.id) ? true : false
            })
          : []
      }
    }
  },
  data: () => ({
    dialog: false,
    selected: [],
    // filtered: [],
    keyword: null
  }),
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    },
    current: {
      handler: function (val) {
        this.selected = val ? _.cloneDeep(val) : []
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    can_be_remove(item) {
      let index = this.dontRemove.length
        ? _.cloneDeep(this.dontRemove).findIndex((i) =>
            i.hasOwnProperty('id') ? i.id === item.id : i === item.id
          )
        : false
      return ~index ? false : true
    },
    remove(item) {
      let index = this.selected.length
        ? this.selected.findIndex((i) =>
            i.hasOwnProperty('id') ? i.id === item.id : i === item.id
          )
        : false
      if (~index) this.selected.splice(index, 1)
    },
    add(item) {
      this.selected.push(item)
    },
    save() {
      if (this.selected_ids.length) {
        this.$emit('save', this.selected_ids)
      }
    }
  }
}
</script>
<style></style>
