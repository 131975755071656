<template>
  <v-card elevation="3" class="message__tab rounded-10" min-height="90vh">
    <v-row dense class="fill-height" align="stretch" justify="center">
      <v-col cols="12" sm="12" md="4" class="pr-0">
        <UsersList
          @open-manage-members="manage_group_members_dialog = true"
          :participants="participants"
          v-model="activeTab"
          :has-client-tab="!is_client_tab_disabled"
          :has-team-tab="!is_team_tab_disabled"
        />
      </v-col>
      <v-col cols="12" sm="12" md="8" class="pl-0">
        <v-sheet
          class="
            fullwidth
            rounded-tr-10
            border-1
            border-color--grey
            d-flex
            align-center
            justify-center
          "
          height="50"
          color="white"
        >
          <span v-if="activeTab === 'team'" class="fw-900 primary--text">
            Team Chat
          </span>
          <span v-else class="fw-900 primary--text">Client Chat</span>
        </v-sheet>
        <ChatBox
          height="100vh"
          class="rounded-tr-0 rounded-br-10"
          :allow-message-delete="false"
          :has-toolbar="false"
        ></ChatBox>
      </v-col>
    </v-row>
    <ManageChatMembers
      v-model="manage_group_members_dialog"
      :current="participants"
      :list="project_members"
      :dont-remove="dontRemove"
      @save="syncChatParticipants"
    ></ManageChatMembers>
  </v-card>
</template>

<script>
import UsersList from './components/UsersListv2.vue'
import ChatBox from '../Chat/components/ChatBox/ChatBox.vue'
import request from '@/services/axios_instance'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ManageChatMembers from './components/ManageChatMembers.vue'

export default {
  name: 'Messages',
  components: {
    ChatBox,
    UsersList,
    ManageChatMembers
  },
  props: {
    id: [String, Number]
  },
  data: () => ({
    activeTab: 'team',
    active_chat_index: 0,
    manage_group_members_dialog: false
  }),
  watch: {
    id: {
      handler: function (val) {
        if (val) {
          this.fetchConversationByProject({ id: val })
        }
      },
      immediate: true
    },
    activeTab: {
      handler: function (val) {
        if (val === 'team' && !this.user.is_client && this.team_conversation)
          this.setActiveConversation(this.team_conversation)
        else if (val === 'client' && this.client_conversation) {
          let inChat = this.client_conversation.participants.find(
            (i) => i.id == this.user.id
          )
            ? true
            : false
          if (inChat) {
            this.setActiveConversation(this.client_conversation)
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('project', ['project_members', 'project']),
    ...mapGetters('conversations', [
      'team_conversations',
      'conversations',
      'client_conversations'
    ]),
    team_conversation() {
      const convo = this.team_conversations.find((i) => i.project_id == this.id)
      return typeof convo != 'undefined' && convo ? convo : null
    },
    client_conversation() {
      const convo = this.client_conversations.find(
        (i) => i.project_id == this.id
      )
      return typeof convo != 'undefined' && convo ? convo : null
    },
    dontRemove() {
      if (!this.project_members.length) {
        return []
      }
      return this.project_members.filter((i) =>
        i && (i.is_admin || i.is_client || i.is_manager) ? true : false
      )
    },
    active_chat() {
      return this.activeTab === 'team'
        ? this.team_conversation
        : this.client_conversation
    },
    user_list_title() {
      if (!this.active_chat) return ''
      return this.active_chat.type === 'client'
        ? 'Client Group Chat'
        : 'Team Group Chat'
    },
    participants() {
      if (!this.active_chat) return []
      return this.active_chat.participants
    },
    is_client_tab_disabled() {
      if (!this.client_conversation) return true
      else if (this.user.is_admin) return false
      else if (
        !this.client_conversation.participants ||
        !this.client_conversation.participants.length
      ) {
        return false
      } else {
        let index = this.client_conversation.participants.findIndex(
          (i) => i.id === this.user.id
        )
        return !~index
      }
    },
    is_team_tab_disabled() {
      if (!this.team_conversation) return true
      else if (this.user.is_admin) return false
      else if (
        !this.team_conversation.participants ||
        !this.team_conversation.participants.length
      ) {
        return false
      } else {
        let index = this.team_conversation.participants.findIndex(
          (i) => i.id === this.user.id
        )
        return !~index
      }
    }
  },
  methods: {
    ...mapMutations('conversations', ['closeAllActiveConversations']),
    ...mapActions('conversations', [
      'setActiveConversation',
      'updateConversation',
      'fetchConversationByProject'
    ]),
    syncChatParticipants(payload) {
      let modified_payload = {
        users: payload,
        group_name: this.project.project_name
      }
      const vm = this
      this.updateConversation({
        conversation_id: this.active_chat.id,
        payload: modified_payload,
        cb: () => {
          vm.closeAllActiveConversations()
          vm.manage_group_members_dialog = false
          vm.appSnackbar('Chat participants updated')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped src="./Messages.scss"></style>
