<template>
  <v-card
    outlined
    tile
    style="overflow: hidden"
    class="mx-auto rounded-bl-10 rounded-tl-10 users-list fill-height"
    flat
  >
    <v-sheet
      height="50"
      class="d-flex align-center justify-center rounded-0 border-color--grey"
    >
      <v-tabs
        v-model="activeTab"
        fixed-tabs
        class="border-bottom-1 border-color--grey"
        background-color="white"
      >
        <v-tab
          v-if="hasTeamTab"
          class="primary--text lighten-2"
          active-class="fw-900"
          href="#team"
        >
          Team Chat
        </v-tab>
        <v-tab
          v-if="hasClientTab"
          class="primary--text lighten-2"
          active-class="fw-900"
          href="#client"
        >
          Client Chat
        </v-tab>
      </v-tabs>
    </v-sheet>
    <v-card-text>
      <v-sheet
        v-if="activeTab === 'client' && can_manage"
        height="50"
        class="d-flex align-center justify-center"
      >
        <v-btn
          color="primary"
          depressed
          class="text-none"
          @click="$emit('open-manage-members')"
        >
          <v-icon left> mdi-message-cog </v-icon> Manage Members
        </v-btn>
      </v-sheet>
      <v-sheet
        height="100"
        v-if="clients.length && activeTab === 'client'"
        class="d-flex fullwidth flex-column my-2"
      >
        <label class="fw-700 fs-16 primary--text">Clients</label>
        <div class="d-flex align-center justify-start flex-wrap">
          <Avatar
            v-for="user in clients"
            :key="user.id"
            :user="user"
            icon-only
          ></Avatar>
        </div>
      </v-sheet>
      <v-sheet
        v-if="members.length"
        height="100"
        class="d-flex fullwidth flex-column px-2 my-2"
      >
        <label class="fw-700 fs-16 primary--text">Members</label>
        <div class="d-flex align-center justify-start flex-wrap">
          <Avatar
            v-for="user in members"
            :key="user.id"
            :user="user"
            icon-only
          ></Avatar>
        </div>
      </v-sheet>
      <Empty
        v-if="!participants.length"
        headline="No active conversation"
      ></Empty>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    hasTeamTab: { type: Boolean, default: false },
    hasClientTab: { type: Boolean, default: false },
    value: { type: String, default: 'team', require: true },
    participants: Array
  },
  data: () => ({
    activeTab: 'team'
  }),
  computed: {
    ...mapGetters(['user']),
    clients() {
      if (!this.participants.length) return []
      return this.participants.filter((i) => i.is_client)
    },
    members() {
      if (!this.participants.length) return []
      return this.participants.filter((i) => !i.is_client)
    },
    can_manage() {
      return this.user.is_admin || this.user.is_manager
    }
  },
  watch: {
    value(val) {
      this.activeTab = val
    },
    activeTab: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
.online {
  background-color: $green;
}

.offline {
  background-color: $gray;
}

.users-list {
  .sidebar__chatlist {
    margin: 10px;
    background-color: $white;
    border: 1px solid $tableBorderBlue;
    border-radius: 10px;
  }

  .user {
    padding: 10px;
    text-align: center;
    display: flex;
  }

  .user__img {
    height: 50px;
    width: 50px;
    max-width: 50px;
    border-radius: 50%;
    border: 1px solid $borderLightGray;
    position: relative;

    .status {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 15px;
      height: 15px;
      border: 2px solid $lightViolete;
      border-radius: 50%;
    }

    .dnd {
      background-color: $red;
    }
  }

  .chatlist-header {
    font-size: 20px;
    text-align: left;
    font-weight: 500;
    color: $textDark;
    text-transform: capitalize;
  }

  .user__name {
    margin-left: 10px;
    font-size: 12px;
    margin: auto 10px;
    text-align: left;
    font-weight: 500;
    color: $textDark;
    text-transform: capitalize;
  }

  @include styledScrollFor('.friend__list');

  .friend__list {
    overflow: auto;
    max-height: 500px;

    .friend {
      border-top: 1px solid $borderLightGray;
    }
  }

  .view__more {
    text-align: center;
    padding: 10px;
    border-top: 1px solid $borderLightGray;

    .view__more_btn {
      color: $tableDarkText;
    }
  }
}
</style>
